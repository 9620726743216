exports.components = {
  "component---gatsby-theme-pitayan-src-pages-404-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/404/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-404-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-authors-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/authors/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-authors-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-categories-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/categories/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-categories-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-all-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/allPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-all-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-author-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/authorPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-author-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-category-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/categoryPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-category-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-post-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/post/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-post-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-site-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/site/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-site-index-tsx" */)
}

